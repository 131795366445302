var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CModal",
    {
      staticClass: "notification-modal",
      attrs: {
        color: "info",
        show: _vm.show,
        title: _vm.title,
        centered: true
      },
      on: {
        "update:show": function($event) {
          return _vm.onClose()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("CButton", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: _vm.cancelLabel,
                    expression: "cancelLabel"
                  }
                ],
                attrs: { color: "light" },
                on: {
                  click: function($event) {
                    return _vm.onClose()
                  }
                }
              }),
              _c("CButton", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: _vm.okLabel,
                    expression: "okLabel"
                  }
                ],
                attrs: { color: "primary" },
                on: {
                  click: function($event) {
                    return _vm.onOk()
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CRow",
        [
          _c("CCol", {
            attrs: { md: "12" },
            domProps: { innerHTML: _vm._s(_vm.text) }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }