import { render, staticRenderFns } from "./CustomerOrderIndex.vue?vue&type=template&id=bf8a1956&"
import script from "./CustomerOrderIndex.vue?vue&type=script&lang=ts&"
export * from "./CustomerOrderIndex.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bf8a1956')) {
      api.createRecord('bf8a1956', component.options)
    } else {
      api.reload('bf8a1956', component.options)
    }
    module.hot.accept("./CustomerOrderIndex.vue?vue&type=template&id=bf8a1956&", function () {
      api.rerender('bf8a1956', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customer/orders/CustomerOrderIndex.vue"
export default component.exports