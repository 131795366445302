var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "img-rating" }, [
    _c(
      "span",
      {
        on: {
          click: function($event) {
            return _vm.setRating(0)
          }
        }
      },
      [
        _vm.withZero
          ? _c("CIcon", {
              attrs: {
                name: "cil-ban",
                height: _vm.size,
                alt: "Zurücksetzen",
                width: _vm.size
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm.isDarkMode
      ? _c(
          "span",
          [
            _vm._l(_vm.rating, function(idx) {
              return _c("img", {
                key: "full-" + idx,
                attrs: {
                  height: _vm.size,
                  width: _vm.size,
                  alt: "star",
                  src: _vm.image(true),
                  "data-cy": "rating-" + idx
                },
                on: {
                  click: function($event) {
                    return _vm.setRating(idx)
                  }
                }
              })
            }),
            _vm._l(5 - _vm.rating, function(idx) {
              return _c("img", {
                key: "empty-" + idx,
                attrs: {
                  height: _vm.size,
                  width: _vm.size,
                  alt: "empty star",
                  src: _vm.image(false),
                  "data-cy": "rating-" + (idx + _vm.rating)
                },
                on: {
                  click: function($event) {
                    return _vm.setRating(idx + _vm.rating)
                  }
                }
              })
            })
          ],
          2
        )
      : _vm._e(),
    !_vm.isDarkMode
      ? _c(
          "span",
          [
            _vm._l(_vm.rating, function(idx) {
              return _c(
                "span",
                {
                  key: "full-" + idx,
                  attrs: { "data-cy": "rating-" + idx },
                  on: {
                    click: function($event) {
                      return _vm.setRating(idx)
                    }
                  }
                },
                [
                  _c("CIcon", {
                    attrs: {
                      name: "cis-star",
                      height: _vm.size,
                      width: _vm.size
                    }
                  })
                ],
                1
              )
            }),
            _vm._l(5 - _vm.rating, function(idx) {
              return _c(
                "span",
                {
                  key: "empty-" + idx,
                  attrs: { "data-cy": "rating-" + (idx + _vm.rating) },
                  on: {
                    click: function($event) {
                      return _vm.setRating(idx + _vm.rating)
                    }
                  }
                },
                [
                  _c("CIcon", {
                    attrs: {
                      name: "cil-star",
                      height: _vm.size,
                      width: _vm.size
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }